export default {
  objectMerge,
  attributeEquals,
  scrollTo,
  toggleClass,
  dateFormat,
  stringToDate,

  transformOssToPicsh,
  imageMogrWidth
};

function transformOssToPicsh(ossUrl) {
  // 目前buket在上海
  return ossUrl.replace("cos.ap-shanghai", "picsh");
}

// 将图片按比例缩放
function imageMogrWidth(ossUrl, widthPx) {
  let picsh = transformOssToPicsh(ossUrl) + "?imageMogr2/thumbnail/" + widthPx + "x";
  return picsh;
}

/**
 * 把source中的属性拷贝到target中，同名属性会被覆盖
 * @returns {*}   返回target
 */
function objectMerge(target, source) {
  if (source == null) {
    return target;
  }
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  });
  return target
}

function attributeEquals(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  if (Array.isArray(obj1)) {
    if (!Array.isArray(obj2) || obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!attributeEquals(obj1[i], obj2[i])) {
        return false;
      }
    }
  }

  let notEqual = false;
  Object.keys(obj1).forEach((property) => {
    if (notEqual) return;

    const obj1Property = obj1[property];
    const obj2Property = obj2[property];
    if ((typeof obj1Property === 'object' && !attributeEquals(obj1Property, obj2Property))
      || obj1Property != obj2Property) {
      notEqual = true;
    }
  });
  return !notEqual;
}

/**
 * 滚动到指定位置
 * @param element   带有滚动条的元素，DOM对象
 * @param to        滚动到的高度，int
 * @param duration  动画时间
 */
function scrollTo(element, to, duration) {
  if (duration <= 0) return;
  const difference = to - element.scrollTop;
  const perTick = difference / duration * 10;
  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10)
  }, 10)
}

/**
 * 切换类
 * @param element     DOM对象
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}


Date.prototype.format = function (fmt) {
  const o = {
    "M+": this.getMonth() + 1,                 //月份
    "d+": this.getDate(),                    //日
    "h+": this.getHours(),                   //小时
    "m+": this.getMinutes(),                 //分
    "s+": this.getSeconds(),                 //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
};

function dateFormat(fmt, date) {
  let ret;
  let opt = {
    "y+": date.getFullYear().toString(),        // 年
    "M+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "m+": date.getMinutes().toString(),         // 分
    "s+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}

function stringToDate(str) {
  const tempStrs = str.split(" ");
  const dateStrs = tempStrs[0].split("-");
  const year = parseInt(dateStrs[0], 10);
  const month = parseInt(dateStrs[1], 10) - 1;
  const day = parseInt(dateStrs[2], 10);
  const timeStrs = tempStrs[1].split(":");
  const hour = parseInt(timeStrs [0], 10);
  const minute = parseInt(timeStrs[1], 10);
  const second = parseInt(timeStrs[2], 10);
  const date = new Date(year, month, day, hour, minute, second);
  return date;
}
