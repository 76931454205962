const uiController = {

  status: {
    sidebar: {
      opened: !+window.localStorage.getItem('isSideBarOpen'),
      withoutAnimation: false
    }
  },

  mutations: {

  }

};

export default uiController;
