<template>
  <el-container>
    <el-main>
      <el-table ref="itemTable" :data="queryResult.records">
        <el-table-column label="姓名" prop="name" align="center" width="80"></el-table-column>
        <el-table-column label="留言内容" align="center">
          <template slot-scope="scope">
            {{scope.row.content ? (scope.row.content.length > 30 ? scope.row.content.slice(0, 30) + "..." : scope.row.content) : ""}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="() => {selectNews = scope.row; contentDialogVisible = true}">查看</el-button>
            <el-button type="danger" @click="() => {selectNews = scope.row; deleteDialogVisible = true}">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex margin-top-sm">
        <el-pagination background layout="prev, pager, next" :total="queryResult.total"
                       :current-page="queryResult.current"
                       :page-size="queryParam.size" @current-change="currentPageChange"
                       @prev-click="currentPageChange(queryParam.current - 1)"
                       @next-click="currentPageChange(queryParam.current + 1)">
        </el-pagination>
      </div>
    </el-main>

    <el-dialog title="提示" :visible.sync="contentDialogVisible" :before-close="handleClose">
      <el-form label-width="120px">
        <el-form-item label="姓名">
          <span>{{selectNews.name}}</span>
        </el-form-item>
        <el-form-item label="电话">
          <span>{{selectNews.tel}}</span>
        </el-form-item>
        <el-form-item label="邮箱">
          <span>{{selectNews.email}}</span>
        </el-form-item>
        <el-form-item label="QQ">
          <span>{{selectNews.qq}}</span>
        </el-form-item>
        <el-form-item label="留言内容">
          <span>{{selectNews.content}}</span>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="deleteDialogVisible" width="30%" :before-close="handleClose">
      <span>确认删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="remove">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>


<script>
  export default {
    data() {
      return {
        contentDialogVisible: false,
        deleteDialogVisible: false,

        selectNews: {},

        queryParam: {
          current: 1,
          size: 10,
          total: 0
        },
        queryResult: {
          records: [],
          total: 1,
          current: 1,
        },
      }
    },
    created() {
      window.vue = this;
      this.query();
    },
    methods: {
      remove() {
        this.$ajax.post('/contact-record/delete', {newsId: this.selectNews.newsId}).then(res => {
          this.deleteDialogVisible = false;
          this.query();
        });
      },

      query() {
        const loading = this.$loading();
        this.$ajax.get('/contact-record', {params: this.queryParam}).then(res => {
          if (res.code !== 100) {
            return;
          }

          this.queryResult = res.data;
          this.queryParam.total = this.queryResult.total;
        }).finally(() => loading.close());
      },
      currentPageChange(currentPage) {
        this.queryParam.current = currentPage;
        this.queryItem();
      },
    }
  }
</script>


<style>
</style>