<template>
  <scroll-bar>
    <el-menu
      mode="vertical"
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="hideMenu"
      background-color="#ffffff"
      text-color="#000000"
      active-text-color="#f37f10"
    >
      <!-- LOGO -->
      <!--<div class="logo-container">-->
        <!--<img class="logo-img" src="@/assets/logo.png"/>-->
      <!--</div>-->

      <menu-item style="margin-top: 50px;" v-bind:menus="menus" sub-mode="true" @menuItemClick="onMenuItemClick"></menu-item>
    </el-menu>
  </scroll-bar>
</template>

<script>
  import ScrollBar from '@/components/ScrollBar'
  import MenuItem from './MenuItem'
  import {mapGetters} from 'vuex'

  export default {
    name: 'Menu',
    components: {
      MenuItem, ScrollBar
    },
    props: ['hideMenu'],
    computed: {
      ...mapGetters([
        'menus'
      ])
    },
    methods: {
      onMenuItemClick(e) {
        this.$emit('menuChanged', e);
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  /* LOGO */
  .logo-container {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-title {
      height: 50px;
      line-height: 50px;
      font-size: 25px;
      margin-left: 10px;
      color: aliceblue;
    }
    img {
      width: 70%;
    }
  }

  .hide-menu {
    .logo-container {
      .logo-title {
        display: none;
      }
      img {
        width: 100%;
      }
    }
  }

  /* logo区 */

</style>
