<template>
  <el-menu class="navbar" mode="horizontal">
    <!-- 菜单收缩切换 -->
    <div class="hamburger-container pointer" @click="$emit('toggle-menu-hide')">
      <svg t="1492500959545" class="hamburger" :class="{'is-active':hideMenu}" fill="#BFCBD9" viewBox="0 0 1024 1024"
           version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1691" width="64" height="64">
        <path
          d="M966.8023 568.849776 57.196677 568.849776c-31.397081 0-56.850799-25.452695-56.850799-56.850799l0 0c0-31.397081 25.452695-56.849776 56.850799-56.849776l909.605623 0c31.397081 0 56.849776 25.452695 56.849776 56.849776l0 0C1023.653099 543.397081 998.200404 568.849776 966.8023 568.849776z"
          p-id="1692"></path>
        <path
          d="M966.8023 881.527125 57.196677 881.527125c-31.397081 0-56.850799-25.452695-56.850799-56.849776l0 0c0-31.397081 25.452695-56.849776 56.850799-56.849776l909.605623 0c31.397081 0 56.849776 25.452695 56.849776 56.849776l0 0C1023.653099 856.07443 998.200404 881.527125 966.8023 881.527125z"
          p-id="1693"></path>
        <path
          d="M966.8023 256.17345 57.196677 256.17345c-31.397081 0-56.850799-25.452695-56.850799-56.849776l0 0c0-31.397081 25.452695-56.850799 56.850799-56.850799l909.605623 0c31.397081 0 56.849776 25.452695 56.849776 56.850799l0 0C1023.653099 230.720755 998.200404 256.17345 966.8023 256.17345z"
          p-id="1694"></path>
      </svg>
    </div>

    <span style="font-weight: bold;font-size: 18px;">浙江浩凯滤业股份有限公司</span>

    <!--<el-breadcrumb class="breadcrumb-container app-breadcrumb" separator="/" style="line-height: 46px;">-->
      <!--<transition-group name="breadcrumb">-->
        <!--<el-breadcrumb-item v-for="(item)  in levelList" :key="item.id" v-if='item.name'>-->
          <!--<span class="no-redirect">{{item.name}}</span>-->
        <!--</el-breadcrumb-item>-->
      <!--</transition-group>-->
    <!--</el-breadcrumb>-->

    <div class="right-menu">
      <span>图片上传前建议先压缩，会影响用户打开速度，非常影响用户体验</span>
      <el-dropdown class="avatar-container right-menu-item" trigger="click">
        <div class="avatar-wrapper">
          <!--<img class="user-avatar" src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80">-->
          <span>点击</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <span @click="logout" style="display:block;">注销</span>
          </el-dropdown-item>
          <!--<router-link to="/home/home">-->
            <!--<el-dropdown-item>首页</el-dropdown-item>-->
          <!--</router-link>-->
          <!--<el-dropdown-item divided>-->
            <!--<span @click="logout" style="display:block;">注销</span>-->
          <!--</el-dropdown-item>-->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-menu>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: ['hideMenu', 'routes', 'currentMenu', 'levelList'],
    computed: {
      ...mapGetters([
      ])
    },
    created() {
      window.app = this;
    },
    data: function () {
      return {
      }
    },
    methods: {
      logout() {
        this.$common.removeUser();
        this.$router.push({path: '/login'});
      },
    },
    mounted: function () {
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-dropdown-link.el-dropdown-selfdefine {
    font-size: 20px;
    font-weight: 500;
  }

  .navbar {
    height: 46px;
    line-height: 46px;
    border-radius: 0px !important;
    .hamburger-container {
      line-height: 58px;
      height: 46px;
      float: left;
      padding: 0 10px;
    }
    .breadcrumb-container {
      float: left;
    }
    .errLog-container {
      display: inline-block;
      vertical-align: top;
    }
    .right-menu {
      float: right;
      height: 100%;
      &:focus {
        outline: none;
      }
      .right-menu-item {
        display: inline-block;
        margin: 0 8px;
      }
      .screenfull {
        height: 20px;
      }
      .international {
        vertical-align: top;
      }
      .theme-switch {
        vertical-align: 15px;
      }
      .avatar-container {
        height: 50px;
        margin-right: 30px;
        .avatar-wrapper {
          cursor: pointer;
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .user-avatar {
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }
          .el-icon-caret-bottom {
            position: absolute;
            right: -20px;
            top: 15px;
            font-size: 12px;
          }
        }
        span {
          margin: 0 10px;
        }
      }
    }
  }

  .hamburger {
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    transition: .38s;
    transform-origin: 50% 50%;
  }

  .hamburger.is-active {
    transform: rotate(0deg);
  }
</style>
