<template>

  <div class="app-wrapper" :class="classObj">
    <!-- 左侧菜单 -->
    <side-menu class="sidebar-container" :hide-menu="isMenuHidden" @menuChanged="onMenuChange"></side-menu>

    <!-- 右侧内容 -->
    <div class="main-container">
      <navbar v-on:toggle-menu-hide="toggleMenuHide" :hide-menu="isMenuHidden" :level-list="menuLevelList"></navbar>
      <!-- 50px是navbar的盒子高度 -->
      <app-main></app-main>
    </div>
  </div>

</template>

<script>
import SideMenu from './Menu'
import Navbar from './Navbar'
import AppMain from './AppMain'
import { mapGetters } from 'vuex'

export default {
  name: 'layout',
  components: {
    SideMenu, Navbar, AppMain
  },
  data: function () {
    return {
      isMenuHidden: false,
      menuLevelList: []
    }
  },
  created: function () {
    this.$store.dispatch('getMenus');
  },
  computed: {
    classObj() {
      return {
        'hide-menu': this.isMenuHidden
      }
    },
    ...mapGetters([
      'menus'
    ])
  },
  methods: {
    toggleMenuHide () {
      this.isMenuHidden = !this.isMenuHidden;
    },
    onMenuChange(menu) {
      this.generateMenuLevelList(menu, this.menus, []);
    },
    generateMenuLevelList(selectedMenu, menuList, menuLevelList) {
      for (let i = 0; i < menuList.length; i++) {
        const m = menuList[i];
        menuLevelList.push(m);

        if (m.id === selectedMenu.id) {
          this.menuLevelList = menuLevelList;
          return true;
        }

        if (m.children && m.children.length > 0) {
          if (this.generateMenuLevelList(selectedMenu, m.children, menuLevelList)) {
            return true;
          }
        }

        menuLevelList.splice(menuLevelList.length - 1, 1);
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-wrapper {
    height: 100%;
    .main-container {
      height: 100%;
    }
  }
</style>
