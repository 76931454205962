/*
  声明了getters，并挂载到vue后，可以通过vuex的mapGetters取到getters声明的函数
  如：
  import mapGetters from 'vuex'
  mapGetters(['sidebar', 'language'])   // 会返回getters中声明的sidebar和language
*/

const getters = {
  // UI效果控制
  sidebar: state => state.uiController.sidebar,     // 左侧菜单收起状态
  menus: state => state.app.menus,
};
export default getters
