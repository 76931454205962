<template>
  <el-container>
    <el-header height="34px">
      <el-button @click="$router.go(-1)" class="back-button" icon="el-icon-back">返回</el-button>
    </el-header>

    <el-main>
      <el-form :model="item" label-width="120px">
        <el-form-item label="商品名称（中文）" class="is-required">
          <el-input v-model="item.zhName" minlength="2" maxlength="200">
            <template slot="append">{{item.zhName ? item.zhName.length : 0}}/200</template>
          </el-input>
        </el-form-item>
        <el-form-item label="商品名称（英文）" class="is-required">
          <el-input v-model="item.enName" minlength="2" maxlength="200">
            <template slot="append">{{item.enName ? item.enName.length : 0}}/200</template>
          </el-input>
        </el-form-item>
        <el-form-item label="商品分类" class="is-required">
          <el-cascader v-model="selectedItemCategoryTypeIds" :options="itemCategories" filterable
                       @change="itemCategoryTypeChange"></el-cascader>
        </el-form-item>
        <el-form-item label="商品封面图" class="is-required">
          <el-upload ref="coverImgUploader" class="img-uploader-card" action="/api/admin/attachment/upload"
                     :headers="$ajax.authcHeader()"
                     :auto-upload="false" list-type="picture-card" :show-file-list="false"
                     :on-change="onCoverImgChange">
            <el-image v-if="coverImgAttachment != null"
                      :src="coverImgAttachment.picshMogrUrl ? coverImgAttachment.picshMogrUrl : coverImgAttachment.url"
                      fit="scale-down"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品头图" class="is-required">
          <el-upload ref="headerImgUploader" action="/api/admin/attachment/upload" :headers="$ajax.authcHeader()"
                     :auto-upload="false" list-type="picture" :show-file-list="false" multiple
                     :on-change="onHeaderImgChange">
            <el-button size="small" type="primary">选择图片</el-button>
          </el-upload>
          <vuedraggable tag="ul" class="el-upload-list el-upload-list--picture" v-model="headerImgAttachments">
            <li v-for="(at, idx) in headerImgAttachments" class="el-upload-list__item is-success" :key="at">
              <img :src="at.picshMogrUrl ? at.picshMogrUrl : at.url" @click="previewHeaderImgs(idx)"
                   class="el-upload-list__item-thumbnail">
              <a class="el-upload-list__item-name" @click="previewHeaderImgs(idx)"><i class="el-icon-document"></i>{{at.name}}</a>
              <i class="el-icon-close" @click="removeHeaderImg(idx)"></i>
            </li>
          </vuedraggable>
        </el-form-item>

        <el-form-item label="正文内容" class="is-required">
          <span>（中文）{{item.zhIntro ? item.zhIntro.length : 0}}</span>
        </el-form-item>
        <Editor ref="zhEditor" v-model="item.zhIntro"></Editor>

        <el-form-item label="正文内容" class="is-required">
          <span>（英文）{{item.enIntro ? item.enIntro.length : 0}}</span>
        </el-form-item>
        <Editor ref="enEditor" v-model="item.enIntro"></Editor>

        <el-form-item>
          <el-button @click="saveItem" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>


<script>
  import vuedraggable from 'vuedraggable';
  import Editor from '@/components/Editor'

  export default {
    components: {vuedraggable, Editor},
    data() {
      return {
        item: {},
        coverImgAttachment: null,
        headerImgPreviewVisible: false,
        headerPreviewCarouselIndex: 0,
        headerImgAttachments: [],
        selectedItemCategoryTypeIds: [],
      }
    },
    created() {
      window.vue = this;
      this.loadItemCategoryTypes();
      const paramItemId = this.$route.query.itemId;
      if (paramItemId) {
        this.loadToUpdateItem(paramItemId);
      }
    },
    methods: {
      // ----------- 商品一二级分类
      loadItemCategoryTypes() {
        this.$ajax.get('/item/category/type')
          .then(response => {
            if (response.code !== 100) {
              return;
            }

            const categories = {};
            const categoryIds = new Set();
            response.data.forEach(t => {
              let c;
              if ((c = categories[t.categoryId]) == null) {
                c = {
                  value: t.categoryId,
                  label: t.zhCategoryName,
                  children: []
                };
                categories[t.categoryId] = c;
                categoryIds.add(t.categoryId);
              }

              c.children.push({
                value: t.typeId,
                label: t.zhName,
                leaf: true,
              })
            });

            const ic = [];
            categoryIds.forEach(cid => {
              ic.push(categories[cid]);
            });
            this.itemCategories = ic;
            if (ic.length > 0) {
              this.itemCategoryTypeChange([ic[0].value, ic[0].children[0].value]);
            }
          });
      },
      itemCategoryTypeChange([categoryId, typeId]) {
        this.item.categoryId = categoryId;
        this.item.typeId = typeId;
        this.selectedItemCategoryTypeIds = [categoryId, typeId];
      },

      loadToUpdateItem(itemId) {
        const loading = this.$loading();
        this.$ajax.get('/item/' + itemId).then(res => {
          this.item = res.data;
          this.$refs.zhEditor.setContent(this.item.zhIntro);
          this.$refs.enEditor.setContent(this.item.enIntro);
          // 对齐数据，一二级分类
          this.itemCategoryTypeChange([this.item.categoryId, this.item.typeId]);
          // 封面图
          this.coverImgAttachment = this.item.coverImgAttachment;
          this.coverImgAttachment.picshMogrUrl = this.$utils.imageMogrWidth(this.item.coverImgAttachment.url, 150);
          // 头图
          this.headerImgAttachments = this.item.headAttachments;
          if (this.headerImgAttachments) {
            this.headerImgAttachments.forEach(at => {
              at.name = at.attachmentName;
              at.picshMogrUrl = this.$utils.imageMogrWidth(at.url, 80);
            });
          }
        }).finally(() => loading.close());
      },

      // ----------- 封面图
      onCoverImgChange(file) {
        if (this.coverImgAttachment !== file) {
          this.coverImgAttachment = file;
        }
      },
      uploadCoverImg() {
        return new Promise((resolve, reject) => {
          if (this.unUploadedFileCount(this.$refs.coverImgUploader.uploadFiles) <= 0) {
            resolve();
            return;
          }

          this.$refs.coverImgUploader.onSuccess = (response, file, fileList) => {
            if (response.code !== 100) {
              reject(response);
            } else {
              file.attachmentId = response.data.attachmentId;
              resolve(file);
            }
          };
          this.$refs.coverImgUploader.submit();
        });
      },
      // ----------- 头图
      onHeaderImgChange(file) {
        // FIX Element的upload，在上传成功时，也会触发on-change，这里需要判断一下
        if (this.headerImgAttachments.find(at => at === file) == null) {
          this.headerImgAttachments.push(file);
        }

      },
      removeHeaderImg(idx) {
        const removed = this.headerImgAttachments.splice(idx, 1)[0];
        // 如果移除的是还没有上传的文件，则需要从上传列表中移除
        if (removed && removed.attachmentId == null) {
          const $uploader = this.$refs.headerImgUploader;
          const idxInUploadFiles = $uploader.uploadFiles.findIndex(f => f.url === removed.url);
          if (idxInUploadFiles !== -1) {
            $uploader.uploadFiles.splice(idxInUploadFiles, 1);
          }
        }
        return false;
      },
      previewHeaderImgs(atIdx) {
        this.headerImgPreviewVisible = true;
        this.headerPreviewCarouselIndex = atIdx;
        this.$refreshData(this.headerImgAttachments);
      },
      uploadHeaderImg() {
        return new Promise((resolve, reject) => {
          if (this.unUploadedFileCount(this.$refs.headerImgUploader.uploadFiles) <= 0) {
            resolve();
            return;
          }

          this.$refs.headerImgUploader.onSuccess = (response, file, fileList) => {
            if (response.code !== 100) {
              reject(response);
            } else {
              file.attachmentId = response.data.attachmentId;
              if (fileList.find(f => f.attachmentId == null) == null) {
                resolve(file);
              }
            }
          };
          this.$refs.headerImgUploader.submit();
        });
      },

      checkBeforeSave() {
        return true;
      },
      saveItem() {
        // 校验数据
        if (!this.checkBeforeSave()) {
          return;
        }
        const loading = this.$loading();
        // 文件上传
        let uploadPromise = Promise.all([
          this.uploadCoverImg(),
          this.uploadHeaderImg()
        ]);
        uploadPromise.then(() => {
          // 调整数据结构
          this.item.coverAttachmentId = this.coverImgAttachment.attachmentId;
          this.item.headAttachmentIds = this.headerImgAttachments.map(at => at.attachmentId);

          if (this.item.itemId) {
            this.doUpdateItem(this.item).then(this.goBack.bind(this)).finally(() => loading.close());
          } else {
            this.doAddItem(this.item).then(this.goBack.bind(this)).finally(() => loading.close());
          }
        }).catch(response => {
          this.$message.error(`商品上传失败：${response.msg}`);
        }).finally(() => {
          loading.close();
        });

      },
      doAddItem(item) {
        return new Promise((resolve, reject) => {
          // 调用保存接口
          this.$ajax.post('/item/add', item).then(res => {
            if (res.code !== 100) {
              this.$message.error(res.msg);
              reject();
              return;
            }
            this.$message.success("新增商品成功");
            resolve();
          }).catch(reject);
        });
      },
      doUpdateItem(item) {
        return new Promise((resolve, reject) => {
          // 调用保存接口
          this.$ajax.post('/item/update', item).then(res => {
            if (res.code !== 100) {
              this.$message.error(res.msg);
              reject();
              return;
            }
            this.$message.success("修改商品成功");
            resolve();
          }).catch(reject);
        });
      },


      // 统计未上传的文件数量，status为ready的是未上传的
      unUploadedFileCount(files) {
        return files.filter(f => f.status === 'ready').length;
      },
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>


<style>
</style>