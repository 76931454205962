<template>
  <el-container>
    <el-header>
      <el-button @click="addDialogVisible = true">新建分类</el-button>
    </el-header>

    <el-main>
      <el-table :data="categoriesTypes" tooltip-effect="dark" size="small">
        <el-table-column prop="categoryId" label="编号"/>
        <el-table-column prop="categoryName" label="一级分类">
          <template slot-scope="scope">
            {{formatItemCategoryType(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column prop="zhName" label="中文名称"/>
        <el-table-column prop="enName" label="英文名称"/>
        <el-table-column prop="sort" label="排序"/>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button size="mini" @click="() => {itemCategoryType = scope.row;updateDialogVisible=true}">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteType(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>

    <!-- 新增分类弹窗 -->
    <el-dialog title="新增分类" :visible.sync="addDialogVisible" width="600px">
      <el-form label-width="80px" size="small">
        <el-form-item label="中文名称">
          <el-input v-model="itemCategoryType.zhName"></el-input>
        </el-form-item>
        <el-form-item label="英文名称">
          <el-input v-model="itemCategoryType.enName"></el-input>
        </el-form-item>
        <el-form-item label="一级类目">
          <el-select v-model="itemCategoryType.categoryId" placeholder="请选择">
            <el-option v-for="item in categories" :key="item.categoryId" :label="item.zhName" :value="item.categoryId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="itemCategoryType.sort" :min="1" :max="9999999999"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="addItemCategoryType">保存</el-button>
        <el-button @click="addDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 更新分类弹窗 -->
    <el-dialog title="修改分类" :visible.sync="updateDialogVisible" width="600px">
      <el-form label-width="80px" size="small">
        <el-form-item label="中文名称">
          <el-input v-model="itemCategoryType.zhName"></el-input>
        </el-form-item>
        <el-form-item label="英文名称">
          <el-input v-model="itemCategoryType.enName"></el-input>
        </el-form-item>
        <el-form-item label="一级类目">
          <el-select v-model="itemCategoryType.categoryId" placeholder="请选择">
            <el-option v-for="item in categories" :key="item.categoryId" :label="item.zhName" :value="item.categoryId" />
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="itemCategoryType.sort" :min="1" :max="9999999999"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="updateItemCategory">保存</el-button>
        <el-button @click="updateDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        addDialogVisible: false,
        updateDialogVisible: false,
        categories: [],
        categoriesTypes: [],
        // 新增相关
        itemCategoryType: {
          sort: 1,
        },
      };
    },
    mounted() {
      window.vue = this;
      let result = this.loadCategories();
      result.then(() => {
        this.loadCategoryTypes();
      });
    },
    methods: {
      loadCategories() {
        return this.$ajax.get('/item/category').then(res => {
          if (res.code !== 100) {
            return;
          }
          this.categories = res.data;
        });
      },
      loadCategoryTypes() {
        this.$ajax.get('/item/category/type').then(res => {
          if (res.code !== 100) {
            return;
          }
          this.categoriesTypes = res.data;
        });
      },
      addItemCategoryType() {
        this.$ajax.post('/item/category/type/add', this.itemCategoryType).then(res => {
          if (res.code !== 100) {
            return;
          }
          this.addDialogVisible = false;
          this.itemCategoryType = {sort: 1};
          this.loadCategoryTypes();
        });
      },
      updateItemCategory() {
        this.$ajax.post('/item/category/type/update', this.itemCategoryType).then(res => {
          if (res.code !== 100) {
            return;
          }
          this.updateDialogVisible = false;
          this.loadCategoryTypes();
        });
      },

      formatItemCategoryType(item) {
        const category = this.categories.find(c => c.categoryId === item.categoryId);
        if (category == null) {
          return '';
        } else {
          return category.zhName;
        }
      },

      deleteType(type) {
        // 看看下面是不是有二级类目
        this.$ajax.get('/item', {params: {ypeId: type.typeId}}).then(res => {
          if (res.data && res.data.records && res.data.records.length > 0) {
            this.$message.info("此类目下还有产品，请先删除");
          } else {
            this.$ajax.post('/item/category/type/delete', {typeId: type.typeId}).then(res => {
              this.loadCategoryTypes();
            })
          }
        });
      }
    }

  }
</script>


<style>
</style>
