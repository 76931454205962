export default {

  sessionCacheName: "hao_kai_session",

  user() {
    let jsonUser = localStorage.getItem(this.sessionCacheName);
    if (jsonUser) {
      return JSON.parse(jsonUser);
    }
  },

  setUser(user) {
    localStorage.setItem(this.sessionCacheName, JSON.stringify(user));
  },

  removeUser() {
    localStorage.removeItem(this.sessionCacheName);
  },

  parseConstants(constants, stateValue) {
    for (let constant of constants) {
      if (stateValue === constant.value) {
        return constant.label;
      }
    }
    return "无法判断";
  },

}
