<template>
  <el-container>
    <el-header height="120px">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="$router.push({path: '/news/news-edit'})">新建文章</el-button>
        </el-form-item>
      </el-form>
    </el-header>

    <el-main>
      <el-table ref="itemTable" :data="queryResult.records">
        <el-table-column label="标题" prop="zhTitle" align="center"></el-table-column>
        <el-table-column label="封面图" align="center" width="100">
          <template slot-scope="scope">
            <el-image v-if="scope.row.picshMogrCoverImg" :src="scope.row.picshMogrCoverImg"></el-image>
            <span v-else>无封面</span>
          </template>
        </el-table-column>
        <el-table-column label="分类" prop="type" align="center" width="90"></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreated" align="center"></el-table-column>
        <el-table-column label="是否展示" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.onShow" @change="(v) => {scope.row.onShow = !v; selectNews = scope.row; v ? showDialogVisible = true : hideDialogVisible = true}" active-color="#13ce66" inactive-color="#ff4949" />
          </template>
        </el-table-column>
        <el-table-column label="主文章" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isMain" @change="(v) => {
              scope.row.isMain = !v;
              if (v) {
                if (scope.row.picshMogrCoverImg) {
                  selectNews = scope.row;
                  mainNewsDialogVisible = true;
                } else $message.info('主文章必须要有封面，请先设置');
              } else {
                $message.info('必须要有一个主文章');
              }
            }" active-color="#13ce66" inactive-color="#ff4949" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="$router.push({path: '/news/news-edit',query: {newsId: scope.row.newsId}})">编辑</el-button>
            <el-button type="danger" @click="() => {selectNews = scope.row;deleteDialogVisible = true}">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex margin-top-sm">
        <el-pagination background layout="prev, pager, next" :total="queryResult.total"
                       :current-page="queryResult.current"
                       :page-size="queryParam.size" @current-change="currentPageChange"
                       @prev-click="currentPageChange(queryParam.current - 1)"
                       @next-click="currentPageChange(queryParam.current + 1)">
        </el-pagination>
      </div>
    </el-main>

    <el-dialog title="提示" :visible.sync="showDialogVisible" width="30%">
      <span>确认展示？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="show">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="hideDialogVisible" width="30%">
      <span>确认隐藏？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="hide">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="deleteDialogVisible" width="30%">
      <span>确认删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="remove">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="mainNewsDialogVisible" width="30%">
      <span>将当前文章替换为主文章？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mainNewsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateMain">确 定</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>


<script>
  export default {
    data() {
      return {
        newsTypes: [],
        showDialogVisible: false,
        hideDialogVisible: false,
        deleteDialogVisible: false,
        mainNewsDialogVisible: false,

        selectNews: null,

        queryParam: {
          current: 1,
          size: 10,
          total: 0
        },
        queryResult: {
          records: [],
          total: 1,
          current: 1,
        },
      }
    },
    created() {
      window.vue = this;
      this.query();
    },
    methods: {
      show() {
        this.$ajax.post('/news/show', {newsId: this.selectNews.newsId}).then(res => {
          this.showDialogVisible = false;
          this.query();
        });
      },
      hide() {
        this.$ajax.post('/news/hide', {newsId: this.selectNews.newsId}).then(res => {
          this.hideDialogVisible = false;
          this.query();
        });
      },
      remove() {
        this.$ajax.post('/news/delete', {newsId: this.selectNews.newsId}).then(res => {
          this.deleteDialogVisible = false;
          this.query();
        });
      },

      updateMain() {
        this.$ajax.post('/news/update-main', {newsId: this.selectNews.newsId}).then(res => {
          this.mainNewsDialogVisible = false;
          this.query();
        });
      },

      query() {
        const loading = this.$loading();
        this.$ajax.get('/news', {params: this.queryParam}).then(res => {
          if (res.code !== 100) {
            return;
          }

          this.queryResult = res.data;

          for (let item of this.queryResult.records) {
            if (item.coverImgUrl) item.picshMogrCoverImg = this.$utils.imageMogrWidth(item.coverImgUrl, 100);
          }

          this.queryParam.total = this.queryResult.total;
        }).finally(() => loading.close());
      },
      currentPageChange(currentPage) {
        this.queryParam.current = currentPage;
        this.query();
      },

    }
  }
</script>


<style>
</style>