import Router from 'vue-router'
import routers from './RouterData'

// 创建一个全局唯一的Router实例
const RouterInstance = new Router({
  mode: 'hash',
  routes: routers
});

const RouterInstaller = {
  install: function (Vue) {
    Vue.use(Router);
  },

  createRouter: function () {
    return RouterInstance;
  }
};

export default RouterInstaller;
