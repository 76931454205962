// 引入组件
import App from './App.vue';
import store from './libs/vuex'
// 自定义样式
import '@/assets/styles/index.scss'

Vue.config.productionTip = false;

// Router路由信息引入
import RouterInstaller from './libs/router-installer/index';
Vue.use(RouterInstaller);
const router = RouterInstaller.createRouter();

Vue.use(ELEMENT, {
  size: 'medium'
});

// 菜单
import menus from './libs/router-installer/MenuData';

import request from './utils/request'
import common from "./utils/common";
import utils from './utils/utils'

const Loading = ELEMENT.Loading;

Vue.prototype.$common = common;
Vue.prototype.$ajax = request;
Vue.prototype.$utils = utils;
// 通过$set变更data中变量的属性，达到强制刷新渲染的效果，性能不高，慎用
Vue.prototype.$refreshData = function(variable) {
  this.$delete(variable, '$refreshTicker');
  this.$set(variable, '$refreshTicker', Date.now());
};

async function checkSession() {
    return request.get('/session/get');
}

async function initData() {
    // 加载菜单信息
    let menuData = menus();
    store.dispatch('setMenus', menuData);
}

router.beforeEach((to, from, next) => {
    checkSession().then(sessionRes => {
        if (sessionRes.code === 100) { // 有会话 想去哪里去哪里
            common.setUser(sessionRes.data);

            let loadingInstance = Loading.service({fullscreen: true});
            initData().then(() => {
                loadingInstance.close();
                next();
            });
        } else if (to.path === '/login') { // 不加会死循环
            next()
        } else { // 没有会话 去login
            next({path: '/login'});
        }
    });
});

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

window.app = app;
