<template>
  <el-container>
    <el-header height="120px">
      <el-form size="small" :inline="true">
        <el-form-item label="分类">
          <el-select v-model="queryParam.itemCategoryId" clearable placeholder="一级分类选择" @change="itemCategoryChange">
            <el-option v-for="item in itemCategories" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select v-model="queryParam.itemCategoryTypeId" clearable placeholder="二级分类选择">
            <el-option v-for="item in itemCategoryTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$router.push({path: '/item/item-edit'})">新增商品</el-button>
        </el-form-item>
      </el-form>
    </el-header>

    <el-main>
      <el-table ref="itemTable" :data="queryResult.records">
        <el-table-column label="商品ID" prop="itemId" align="center" width="100"></el-table-column>
        <el-table-column label="封面图" align="center" width="100">
          <template slot-scope="scope">
            <el-image :src="scope.row.picshMogrCoverImg"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="商品名称（中文）" prop="zhName" align="center"></el-table-column>
        <el-table-column label="商品名称（英文）" prop="enName" align="center"></el-table-column>
        <el-table-column label="分类" align="center" width="90">
          <template slot-scope="scope">
            {{formatItemCategoryType(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column label="是否展示" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.onShow" @change="(v) => {scope.row.onShow = !v; selectItem = scope.row; v ? showDialogVisible = true : hideDialogVisible = true}" active-color="#13ce66" inactive-color="#ff4949" />
          </template>
        </el-table-column>
        <el-table-column label="排序" align="center">
          <template slot-scope="scope">
            <span v-if="!scope.row.modifingSort">{{scope.row.sort}}</span>
            <el-input-number v-if="scope.row.modifingSort" v-model="scope.row.sort" :controls="false" size="mini" class="sort-edit-input"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <div v-if="!scope.row.modifingSort" class="flex flex-direction">
              <span class="link-type" @click="$router.push({path: '/item/item-edit',query: {itemId: scope.row.itemId}})">查看</span>
              <span class="link-type" @click="startModifySort(scope.$index)">修改排序</span>
              <span class="link-type" @click="deleteItem(scope.row)">删除</span>
            </div>
            <div v-if="scope.row.modifingSort" class="flex flex-direction">
              <span class="link-type" @click="confirmModifySort(scope.$index)">保存排序</span>
              <span class="link-type" @click="cancelModifySort(scope.$index)">取消</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex margin-top-sm">
        <el-pagination background layout="prev, pager, next" :total="queryResult.total" :current-page="queryResult.current"
                       :page-size="queryParam.size" @current-change="currentPageChange"
                       @prev-click="currentPageChange(queryParam.current - 1)" @next-click="currentPageChange(queryParam.current + 1)">
        </el-pagination>
      </div>
    </el-main>

    <el-dialog title="提示" :visible.sync="showDialogVisible" width="30%">
      <span>确认展示？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="show">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="hideDialogVisible" width="30%">
      <span>确认隐藏？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="hide">确 定</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>


<script>
  export default {
    data() {
      return {
        selectItem: {},
        itemCategories: [],
        itemCategoryTypes: [],
        queryParam: {
          itemCategoryId: null,
          itemCategoryTypeId: null,
          current: 1,
          size: 10,
          total: 0
        },
        queryResult:{
          records: [],
          total: 1,
          current: 1,
        },
        showDialogVisible: false,
        hideDialogVisible: false
      }
    },
    created() {
      window.vue = this;
      this.loadItemCategoryTypes();
      this.query();
    },
    methods: {
      // ----------- 商品一二级分类选择
      loadItemCategoryTypes() {
        this.$ajax.get('/item/category/type')
          .then(response => {
            if (response.code !== 100) {
              return;
            }

            const categories = {};
            const categoryIds = new Set();
            response.data.forEach(t => {
              let c;
              if ((c = categories[t.categoryId]) == null) {
                c = {
                  value: t.categoryId,
                  label: t.zhCategoryName,
                  children: []
                };
                categories[t.categoryId] = c;
                categoryIds.add(t.categoryId);
              }

              c.children.push({
                value: t.typeId,
                label: t.zhName,
                leaf: true,
              })
            });

            const ic = [];
            categoryIds.forEach(cid => {
              ic.push(categories[cid]);
            });
            this.itemCategories = ic;
          });
      },
      itemCategoryChange(v) {
        for (let categorie of this.itemCategories) {
          if (categorie.value == v) {
            this.itemCategoryTypes = categorie.children;
            break;
          }
        }
        this.queryParam.itemCategoryTypeId = null;
      },

      query() {
        const loading = this.$loading();
        this.$ajax.get('/item', {params: this.queryParam})
          .then(response => {
            if (response.code !== 100) {
              return;
            }

            this.queryResult = response.data;
            for (let item of this.queryResult.records) {
              item.picshMogrCoverImg = this.$utils.imageMogrWidth(item.coverImgUrl, 100);
            }

            this.queryParam.total = this.queryResult.total;
          }).finally(() => loading.close());
      },
      formatItemCategoryType(item) {
        const category = this.itemCategories.find(c => c.value === item.categoryId);
        if (category == null) {
          return '';
        } else {
          const type = category.children.find(t => t.value === item.typeId);
          if (type == null) {
            return category.label;
          } else {
            return category.label + ' ' + type.label;
          }
        }
      },
      currentPageChange(currentPage) {
        this.queryParam.current = currentPage;
        this.query();
      },

      startModifySort(atIndex) {
        const record = this.queryResult.records[atIndex];
        record.modifingSort = true;
        record.originSort = record.sort;
        this.$refreshData(this.queryResult.records);
      },
      cancelModifySort(atIndex) {
        const record = this.queryResult.records[atIndex];
        record.modifingSort = false;
        record.sort = record.originSort;
        record.originSort = undefined;
        this.$refreshData(this.queryResult.records);
      },
      confirmModifySort(atIndex) {
        const record = this.queryResult.records[atIndex];
        if (record.sort == null || record.sort < 0) {
          this.$message.error('排序必须为正整数！');
          return;
        }

        this.$ajax.post('/item/update-sort', {
          itemId: record.itemId,
          sort: record.sort
        }).then(res => {
          if (res.code !== 100) {
            return;
          }

          this.query();
        });
      },

      show() {
        this.$ajax.post('/item/show', {itemId: this.selectItem.itemId}).then(res => {
          this.showDialogVisible = false;
          this.query();
        });
      },
      hide() {
        this.$ajax.post('/item/hide', {itemId: this.selectItem.itemId}).then(res => {
          this.hideDialogVisible = false;
          this.query();
        });
      },

      deleteItem(item) {
        if (confirm("确认删除 " + item.zhName + "?")) {
          this.$ajax.post('/item/delete', {itemId: item.itemId}).then(res => {
            this.query();
          });
        }
      }
    }
  }
</script>


<style>
</style>