<template>

  <el-container>
    <el-main>
      <el-row>
        <el-col :xs="1" :sm="4" :md="6" :lg="8" :xl="8">
          <div class="bg-white"></div>
        </el-col>
        <el-col :xs="22" :sm="16" :md="12" :lg="8" :xl="8">
          <el-form  :model="loginForm" :rules="loginFormRule" ref="loginForm" label-position="top" class="shadow login-form">
            <el-form-item label="登录名" prop="account" required>
              <el-input v-model="loginForm.account" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" required>
              <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha" required>
              <el-input v-model="loginForm.captcha" placeholder="验证码"></el-input>
              <img id="captchaImg" :src="captchaData" v-on:click="loadCaptcha">
            </el-form-item>
            <el-form-item>
              <el-button type="success" class="login-btn" @click="login">登录</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="1" :sm="4" :md="6" :lg="8" :xl="8">
          <div class="bg-white"></div>
        </el-col>
      </el-row>
    </el-main>

    <el-footer>
      <div>
        <span>CopyRight 2017 All Right Reserved</span>
        <a href="http://beian.miit.gov.cn">浙ICP备12031803号-1</a>
      </div>
    </el-footer>
  </el-container>

</template>


<script>
  export default {
    data() {
      return {
        captchaData: null,

        loginForm: {},
        loginFormRule: {
          phone: [
            {required: true, message: '请填写账号', trigger: 'blur'},
          ],
          password: [
            {required: true, message: '请填写密码', trigger: 'blur'},
          ],
          captcha: [
            {required: true, message: '请填写验证码', trigger: 'blur'},
          ]
        }
      }
    },
    created() {
      this.loadCaptcha();
    },
    methods: {
      loadCaptcha() {
        this.$ajax.get('/captcha').then(res => {
          if (res.code === 200) {
            this.$message.error(res.msg);
            return;
          }
          this.captchaData = res.data.data;
          this.loginForm.captchaToken = res.data.token;
        });
      },
      login() {
        this.$refs.loginForm.validate(valid => {
          if (!valid) {
            return;
          }

          this.$ajax.get("/login", {
            params: this.loginForm
          }).then(res => {
            if (res.code === 100) {
              this.$common.setUser(res.data);
              this.$router.push({path: '/home'});
            } else {
              if (res.code === 200 && res.msg === "验证码有误") {
                this.loadCaptcha();
              }
            }
          });
        });
      }
    }
  }
</script>

<style lang="scss">
  .login-form {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(238, 223, 223, .6), 0 0 20px 0 hsla(0, 0%, 88.2%, .6);
    padding: 50px 50px;
    width: 100%;
    .login-btn {
      width: 100%;
      background-color: var(--cyan);
    }
    .register-link {
      position: absolute;
      right: 0;
      bottom: -30px;
      height: 20px;
      line-height: 20px;
    }
  }

  .bg-white {
    background: #ffffff;
    min-height: 36px;
  }

  #captchaImg {
    height: 36px;
    width: 100%;
    min-width: 180px;
    margin-top: 20px;
  }
</style>
