import { render, staticRenderFns } from "./news-edit.vue?vue&type=template&id=cfcde20e&"
import script from "./news-edit.vue?vue&type=script&lang=js&"
export * from "./news-edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports