<template>
  <el-container>
    <el-main>
      <el-upload ref="imgUploader" action="/api/admin/attachment/upload" :headers="$ajax.authcHeader()"
                 :auto-upload="false" list-type="picture" :show-file-list="false" multiple
                 :on-change="onImgChange">
            <el-button size="small" type="primary">新增图片</el-button>
            <p>点击图片可以进行切换，可以拖动图片，进行排序</p>
      </el-upload>
      <vuedraggable tag="ul" class="el-upload-list el-upload-list--picture" :options="dragOptions" v-model="attachments">
        <li v-for="(at, idx) in attachments" class="el-upload-list__item is-success" style="height: 120px; display: flex" :key="at">
          <el-upload :ref="imgChangeUploader" class="el-upload-list__item-thumbnail candraggable" :auto-upload="false"
                     list-type="picture" :show-file-list="false" :on-change="(file, fileList) => {
                        changeSingleImg(file, idx)
                     }">
            <img :src="at.localUrl ? at.localUrl : at.url" class="avatar">
          </el-upload>
          <div class="el-upload-list__item-name" style="min-width: 400px;margin-left: 10px">
            <div style="line-height: 40px;">中文名称：<el-input style="max-width: 300px;" v-model="at.zhName" placeholder="请输入"></el-input></div>
            <div style="line-height: 40px;">英文名称：<el-input style="max-width: 300px;" v-model="at.enName" placeholder="请输入"></el-input></div>
          </div>
          <i class="el-icon-close" @click="removeImg(idx)"></i>
        </li>
      </vuedraggable>
      <el-button @click="save" type="primary" style="margin-top: 100px;">保存</el-button>
    </el-main>
  </el-container>
</template>

<script>
  import vuedraggable from 'vuedraggable';

  export default {
    components: {vuedraggable},
    data() {
      return {
        attachments: [],
        dragOptions: {
          handle: '.candraggable'
        }
      }
    },
    created() {
      window.vue = this;
      this.type = this.$route.query.type;
      this.load();
    },

    watch: {
      $route(newValue, oldValue) {
        if (newValue.query.type !== this.type) {
          this.type = newValue.query.type;
          this.load();
        }
      }
    },

    methods: {
      load() {
        this.$ajax.get('/photos?type=' + this.type).then(res => {
          if (res.code !== 100) {
            this.$message.error(res.msg);
            return;
          }
          this.attachments = res.data;
        });
      },

      onImgChange(file) {
        if (this.attachments.find(at => at === file) == null) {
          this.attachments.push(file);
        }
      },

      changeSingleImg(file, idx) {
        file.localUrl = URL.createObjectURL(file.raw);
        let newFile = Object.assign({}, this.attachments[idx], file);
        Vue.set(this.attachments, idx, newFile);
        // 如果存在则替换，不存在则新增
        const $uploader = this.$refs.imgUploader;
        const idxInUploadFiles = $uploader.uploadFiles.findIndex(f => f.url === file.url);
        if (idxInUploadFiles < 0) {
          $uploader.uploadFiles.push(newFile)
        } else {
          $uploader.uploadFiles[idxInUploadFiles] = newFile;
        }
      },

      removeImg(idx) {
        const removed = this.attachments.splice(idx, 1)[0];
        // 如果移除的是还没有上传的文件，则需要从上传列表中移除
        if (removed && removed.attachmentId == null) {
          const $uploader = this.$refs.imgUploader;
          const idxInUploadFiles = $uploader.uploadFiles.findIndex(f => f.url === removed.url);
          if (idxInUploadFiles !== -1) {
            $uploader.uploadFiles.splice(idxInUploadFiles, 1);
          }
        }
        return false;
      },
      uploadImgs() {
        return new Promise((resolve, reject) => {
          if (this.unUploadedFileCount(this.$refs.imgUploader.uploadFiles) <= 0) {
            resolve();
            return;
          }

          this.$refs.imgUploader.onSuccess = (response, file, fileList) => {
            if (response.code !== 100) {
              reject(response);
            } else {
              file.attachmentId = response.data.attachmentId;
              file.url = response.data.url;
              if (fileList.find(f => f.attachmentId == null) == null) {
                resolve(file);
              }
            }
          };
          this.$refs.imgUploader.submit();
        });
      },


      save() {
        // 检查
        for (let attachment of this.attachments) {
          if (!attachment.zhName || !attachment.enName) {
            console.log(attachment)
            this.$message.error("请检查图片名称是否全部填写");
            return;
          }
        }
        const loading = this.$loading();
        // 文件上传
        let uploadPromise = Promise.all([
          this.uploadImgs()
        ]);
        uploadPromise.then(() => {
          // 调用保存接口
          this.$ajax.post('/photos/update', {type: this.type, photos: this.attachments}).then(res => {
            if (res.code !== 100) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success("上传成功");
            this.load();
          });

        }).catch(response => {
          this.$message.error(`上传失败：${response.msg}`);
        }).finally(() => {
          loading.close();
        });
      },

      // 统计未上传的文件数量，status为ready的是未上传的
      unUploadedFileCount(files) {
        return files.filter(f => f.status === 'ready').length;
      },
    }
  }
</script>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
