export default function menuData(permission) {
  let menuData = [
    {
      id: '1',
      name: '首页',
      icon: 'fa fa-home',
      children: [
        {
          id: '101',
          route: '/home/banner-list',
          name: '轮播图',
        }
      ]
    }, {
      id: '2',
      name: '产品中心',
      icon: 'fa fa-book',
      children: [
        {
          id: '201',
          route: '/item/item-list',
          name: '产品列表',
        }, {
          id: '202',
          route: '/item/item-category',
          name: '一级类目',
        }, {
          id: '203',
          route: '/item/item-category-type',
          name: '二级类目',
        }
      ]
    }, {
      id: '3',
      name: '新闻资讯',
      icon: 'fa fa-book',
      route: '/news/news-list',
    }, {
      id: '5',
      name: '公司相册',
      icon: 'fa fa-book',
      children: [
        {
          id: '201',
          route: '/photos/photos?type=企业荣誉',
          name: '企业荣誉',
        }, {
          id: '202',
          route: '/photos/photos?type=园区环境',
          name: '园区环境',
        }
      ]
    }, {
      id: '4',
      name: '联系我们',
      icon: 'fa fa-book',
      route: '/content/contact-record-list',
    }, {
      id: '6',
      name: '图片资源',
      icon: 'fa fa-book',
      route: '/attachment/attachment-list',
    }
  ];

  return menuData;
}
