import {JSEncrypt} from 'jsencrypt'
import common from "./common";

const Message = ELEMENT.Message;
const MessageBox = ELEMENT.MessageBox;

// create an axios instance
const http = axios.create({
  baseURL: "/api/admin", // api的base_url 配置后，请求会拼接对应uri
  timeout: 120000 // request timeout
});

// http.headerSession = "TZ-Session";
http.headerSession = "Authorization";
http.signName = "TZ-Sign";
http.signSplice = "---";
http.encrypt = new JSEncrypt();

// request interceptor 在请求或响应被 then 或 catch 处理前拦截它们
http.interceptors.request.use(config => {
  checkSession();
  // 防止缓存
  if (config.url.indexOf("?") !== -1) {
    config.url = config.url + "&t=" + new Date().getTime();
  } else {
    config.url = config.url + "?t=" + new Date().getTime();
  }
  let user = common.user();
  if (user) {
    config.headers[http.headerSession] = user;
  }
  // if (user && user.securityDigest) {
  //   http.encrypt.setPublicKey(user.securityDigest.publicKey);
  //   config.headers[http.headerSession] = user.sessionId;
  //   config.headers[http.signName] = http.encrypt.encrypt(user.securityDigest.token + http.signSplice + new Date().getTime());
  // }
  return config
}, error => {
  console.log(error); // for debug
  Promise.reject(error)
});

function checkSession() {
  let user = common.user();
  if (user) {
  } else {
    setTimeout(() => checkSession(), 50);
  }
}

http.authcHeader = function () {
  let user = common.user();
  if (user) {
    let headerObj = {};
    headerObj[http.headerSession] = user;
    return headerObj;
  }
  // if (user && user.securityDigest) {
  //   http.encrypt.setPublicKey(user.securityDigest.publicKey);
  //   let headerObj = {};
  //   headerObj[http.headerSession] = user.sessionId;
  //   headerObj[http.signName] = http.encrypt.encrypt(user.securityDigest.token + http.signSplice + new Date().getTime());
  //   return headerObj;
  // }
  return {};
};

// respone interceptor
http.interceptors.response.use(
  response => {
    // 会话认证由 main.js 统一处理
    if (response.config.url.indexOf('/session/get') !== -1) {
      response.data.originResponse = response;
      return response.data
    }
    // 认证相关错误
    if (response.data.code === 400 || response.data.code === 401) {
      // 如果是login的话就不用弹了 先写死了
      if (window.location.hash !== "#/login") {
        MessageBox.alert('认证失败请重新登录', {
          confirmButtonText: '确定',
          showClose: false,
          callback: action => {
            // router.push({path: '/login'})
          }
        })
      }
    } else if (response.data.code !== 100) {
      Message.error(response.data.msg);
    }
    response.data.originResponse = response;
    return response.data
  },
  error => {
    console.log('err' + error);// for debug
    MessageBox({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error)
  });

export default http
