// import Vue from 'vue'
// import Vuex from 'vuex'
import getters from './getters'

import app from './modules/app'
import uiController from './modules/uiController'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app, uiController
  },
  actions: {

  },
  getters
});

export default store
