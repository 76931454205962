const _import = file => () => import('@/views/' + file + '.vue');
const Layout = _import('layout/Layout');

// 路由信息在此声明，应当包含所有的路由信息
export default [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    component: _import('login/login')
  },
  {
    path: '/home',
    component: Layout,
    redirect: '/home/banner-list',
    children: [
      {
        path: '/home/banner-list',
        component: _import('home/banner-list')
      },
      {
        path: '/home/banner-edit',
        component: _import('home/banner-edit')
      }
    ]
  },
  {
    path: '/item',
    component: Layout,
    redirect: '/item/item-list',
    children: [
      {
        path: '/item/item-list',
        component: _import('item/item-list')
      },
      {
        path: '/item/item-edit',
        component: _import('item/item-edit')
      },
      {
        path: '/item/item-category',
        component: _import('item/item-category')
      },
      {
        path: '/item/item-category-type',
        component: _import('item/item-category-type')
      }
    ]
  },
  {
    path: '/news',
    component: Layout,
    redirect: '/news/news-list',
    children: [
      {
        path: '/news/news-list',
        component: _import('news/news-list')
      },
      {
        path: '/news/news-edit',
        component: _import('news/news-edit')
      }
    ]
  },
  {
    path: '/content',
    component: Layout,
    redirect: '/content/contact-record-list',
    children: [
      {
        path: '/content/contact-record-list',
        component: _import('content/contact-record-list')
      }
    ]
  },
  {
    path: '/photos',
    component: Layout,
    redirect: '/photos/photos',
    children: [
      {
        path: '/photos/photos',
        component: _import('photos/photos')
      },
    ]
  },
  {
    path: '/attachment',
    component: Layout,
    redirect: '/attachment/attachment-list',
    children: [
      {
        path: '/attachment/attachment-list',
        component: _import('attachment/attachment-list')
      }
    ]
  },
];
