<template>
  <div class="menu-wrapper">
    <template v-for="menu in menus">
      <!-- 无子菜单，即叶子菜单，直接渲染router-link -->
      <router-link v-if="!hasSubMenus(menu)" :key="menu.name" :to="menu.route">
        <el-menu-item :index="menu.route" class="submenu-title-noDropdown" @click="onMenuItemClick(menu)">
          <i class="svg-icon" :class="menu.icon"></i>
          <span slot="title">{{menu.name}}</span>
        </el-menu-item>
      </router-link>


      <!-- 多个子菜单 -->
      <el-submenu v-else :index="menu.name" :key="menu.name">
        <!-- 先渲染父菜单 -->
        <template slot="title">
          <i class="svg-icon" :class="menu.icon"></i>
          <span>{{menu.name}}</span>
        </template>

        <!-- 再循环渲染所有子菜单 -->
        <template v-for="subM in menu.children">
          <!-- 若子菜单有孙菜单，则递归生成menu-item-->
          <menu-item v-if="hasSubMenus(subM)" class="nest-menu" :menus="[subM]" :sub-mode="true" :key="subM.route"></menu-item>

          <!-- 若无孙菜单，则说明是叶子菜单，渲染router-link -->
          <router-link v-else :key="subM.name" :to="subM.route">
            <el-menu-item :index="subM.route" class="submenu-title-noDropdown" @click="onMenuItemClick(subM)">
              <i class="svg-icon" :class="subM.icon"></i>
              <span slot="title">{{subM.name}}</span>
            </el-menu-item>
          </router-link>
        </template>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: ['menus', 'subMode'],
  methods: {
    hasSubMenus(m) {
      return m.children && m.children.length > 0
    },
    onMenuItemClick(menu) {
      this.$emit('menuItemClick', menu);
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 2em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>

