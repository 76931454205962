<template>
  <el-container>
    <el-header height="34px">
      <el-button @click="$router.go(-1)" class="back-button" icon="el-icon-back">返回</el-button>
    </el-header>

    <el-main>
      <el-form :model="item" label-width="120px">
        <el-form-item label="图片" class="is-required">
          <el-upload ref="imgUploader" class="img-uploader-card" action="/api/admin/attachment/upload"
                     :headers="$ajax.authcHeader()"
                     :auto-upload="false" list-type="picture-card" :show-file-list="false"
                     :on-change="onCoverImgChange">
            <el-image v-if="imgAttachment != null"
                      :src="imgAttachment.picshMogrUrl ? imgAttachment.picshMogrUrl : imgAttachment.url"
                      fit="scale-down"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <i class="text-red">Banner图片最佳尺寸为1366*625，其他尺寸可能会展示变形。</i>
        </el-form-item>
        <el-form-item label="标题（中文）" class="is-required">
          <el-input v-model="banner.zhTitle" minlength="2" maxlength="200">
            <template slot="append">{{banner.zhTitle ? banner.zhTitle.length : 0}}/200</template>
          </el-input>
        </el-form-item>
        <el-form-item label="标题（英文）" class="is-required">
          <el-input v-model="banner.enTitle" minlength="2" maxlength="200">
            <template slot="append">{{banner.enTitle ? banner.enTitle.length : 0}}/200</template>
          </el-input>
        </el-form-item>

        <el-form-item label="正文内容" class="is-required">
          <span>（中文）{{banner.zhContent ? banner.zhContent.length : 0}} / 5000</span>
        </el-form-item>
        <Editor ref="zhEditor" v-model="banner.zhContent"></Editor>

        <el-form-item label="正文内容" class="is-required">
          <span>（英文）{{banner.enContent ? banner.enContent.length : 0}} / 10000</span>
        </el-form-item>
        <Editor ref="enEditor" v-model="banner.enContent"></Editor>

        <el-form-item label="是否跳转" class="is-required">
          <el-switch v-model="banner.isRoute" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          <span>开启后轮播图点击后跳转，可以查看具体内容</span>
        </el-form-item>

        <el-form-item>
          <el-button @click="toSave" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>


<script>
  import Editor from '@/components/Editor'

  export default {
    components: {Editor},
    data() {
      return {
        banner: {
          zhTitle: '',
          enTitle: '',
          isRoute: false
        },
        imgAttachment: null,
      }
    },
    created() {
      window.vue = this;
      const paramNewsId = this.$route.query.bannerId;
      if (paramNewsId) {
        this.query(paramNewsId);
      }
    },
    methods: {

      query(id) {
        const loading = this.$loading();
        this.$ajax.get('/banner/' + id).then(res => {
          this.banner = res.data;
          this.$refs.zhEditor.setContent(this.banner.zhContent);
          this.$refs.enEditor.setContent(this.banner.enContent);

          // 封面图
          this.imgAttachment = this.banner.imgAttachment;
          this.imgAttachment.picshMogrUrl = this.$utils.imageMogrWidth(this.banner.imgAttachment.url, 150);
        }).finally(() => loading.close());
      },

      checkBeforeSave() {
        if (!this.banner.zhContent) {
          this.$message.info("请输入中文内容")
          return false;
        }
        if (this.banner.zhContent.length > 5000) {
          this.$message.info("中文内容不能超过5000")
          return false;
        }
        if (!this.banner.enContent) {
          this.$message.info("请输入英文内容")
          return false;
        }
        if (this.banner.enContent.length > 10000) {
          this.$message.info("英文内容不能超过10000")
          return false;
        }
        return true;
      },
      toSave() {
        // 校验数据
        if (!this.checkBeforeSave()) {
          return;
        }
        const loading = this.$loading();
        // 文件上传
        let uploadPromise = Promise.all([
          this.uploadCoverImg()
        ]);
        uploadPromise.then(() => {
          // 调整数据结构
          this.banner.imgAttachmentId = this.imgAttachment.attachmentId;

          if (this.banner.bannerId) {
            this.update()
          } else {
            this.save();
          }
        }).catch(response => {
          this.$message.error(`上传失败：${response.msg}`);
        }).finally(() => {
          loading.close();
        });
      },
      save() {
        // 调用保存接口
        this.$ajax.post('/banner/add', this.banner).then(res => {
          if (res.code !== 100) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("新增成功");
        });
      },
      update() {
        // 调用保存接口
        this.$ajax.post('/banner/update', this.banner).then(res => {
          if (res.code !== 100) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("修改成功");
        });
      },

      onCoverImgChange(file) {
        if (this.imgAttachment !== file) {
          this.imgAttachment = file;
        }
      },
      uploadCoverImg() {
        return new Promise((resolve, reject) => {
          if (this.unUploadedFileCount(this.$refs.imgUploader.uploadFiles) <= 0) {
            resolve();
            return;
          }

          this.$refs.imgUploader.onSuccess = (response, file, fileList) => {
            if (response.code !== 100) {
              reject(response);
            } else {
              file.attachmentId = response.data.attachmentId;
              resolve(file);
            }
          };
          this.$refs.imgUploader.submit();
        });
      },
      // 统计未上传的文件数量，status为ready的是未上传的
      unUploadedFileCount(files) {
        return files.filter(f => f.status === 'ready').length;
      },
    }
  }
</script>


<style>
</style>