<template>
  <el-container>
    <!-- 头部查询 -->
    <el-header height="90px">
      <el-button type="primary" @click="dialogVisible = true">新增</el-button>
    </el-header>

    <!-- 表格 -->
    <el-main>
      <el-table :data="queryResult.records">
        <el-table-column label="图片" align="center" width="100">
          <template slot-scope="scope">
            <el-image :src="scope.row.picshMogrUrl ? scope.row.picshMogrUrl : scope.row.url"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="路径" prop="url" align="center"></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="deleteItem(scope.row.attachmentId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex margin-top-sm">
        <el-pagination
          layout="prev, pager, next"
          :total="queryResult.total"
          :page-size="queryParam.pageSize"
          :current-page.sync="queryResult.current"
          @current-change="pageChange">
        </el-pagination>
      </div>
    </el-main>

    <el-dialog title="上传" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-upload ref="imgUploader" class="img-uploader-card" action="/api/admin/attachment/add"
                 :headers="$ajax.authcHeader()"
                 :auto-upload="false" list-type="picture-card" :show-file-list="false"
                 :on-change="onCoverImgChange">
        <el-image v-if="imgAttachment != null" :src="imgAttachment.url" fit="scale-down"></el-image>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadCoverImg">上 传</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        selectBanner: {},
        dialogVisible: false,


        imgAttachment: null,
        queryParam: {
          current: 1,
          size: 10,
          total: 0,
        },
        queryResult: {
          records: [],
          total: 1,
          current: 1,
        }
      };
    },

    created() {
      window.vue = this;
      this.query();
    },
    methods: {
      query() {
        this.$ajax.get('/attachment', {params: this.queryParam}).then(res => {
          this.queryResult = res.data;
          for (let item of this.queryResult.records) {
            item.picshMogrUrl = this.$utils.imageMogrWidth(item.url, 100);
          }
        });
      },
      pageChange(page) {
        this.queryParam.current = page;
        this.query();
      },

      onCoverImgChange(file) {
        if (this.imgAttachment !== file) {
          this.imgAttachment = file;
        }
      },
      uploadCoverImg() {
        this.$refs.imgUploader.onSuccess = (response, file, fileList) => {
          if (response.code !== 100) {
            this.$message.error(response.msg);
          } else {
            this.dialogVisible = false;
            this.imgAttachment = null;
            this.query();
          }
        };
        this.$refs.imgUploader.submit();
      },
      deleteItem(attachmentId) {
        if (confirm("确认删除?")) {
          this.$ajax.post('/attachment/delete', {attachmentId: attachmentId}).then(res => {
            this.query();
          });
        }
      }
    }
  }
</script>


<style lang="scss">
  .sort-edit-input.el-input-number {
    width: 50px;
    input {
      padding: 0 2px !important;
    }
  }

  .stock-modify-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
  }

  .stock-edit-input.el-input-number {
    width: 100px;
  }

</style>
