<template>
  <el-container>
    <el-header>
      <el-button @click="addDialogVisible = true">新建分类</el-button>
    </el-header>

    <el-main>
      <el-table :data="categories" tooltip-effect="dark" size="small">
        <el-table-column prop="categoryId" label="编号"/>
        <el-table-column prop="zhName" label="中文名称"/>
        <el-table-column prop="enName" label="英文名称"/>
        <el-table-column prop="sort" label="排序"/>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button size="mini" @click="() => {itemCategory = scope.row;updateDialogVisible=true}">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteItemCategory(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>

    <!-- 新增分类弹窗 -->
    <el-dialog title="新增分类" :visible.sync="addDialogVisible" width="600px">
      <el-form label-width="80px" size="small">
        <el-form-item label="中文名称">
          <el-input v-model="itemCategory.zhName"></el-input>
        </el-form-item>
        <el-form-item label="英文名称">
          <el-input v-model="itemCategory.enName"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="itemCategory.sort" :min="1" :max="9999999999"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="addItemCategory">保存</el-button>
        <el-button @click="addDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 更新分类弹窗 -->
    <el-dialog title="修改分类" :visible.sync="updateDialogVisible" width="600px">
      <el-form label-width="80px" size="small">
        <el-form-item label="中文名称">
          <el-input v-model="itemCategory.zhName"></el-input>
        </el-form-item>
        <el-form-item label="英文名称">
          <el-input v-model="itemCategory.enName"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="itemCategory.sort" :min="1" :max="9999999999"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="updateItemCategory">保存</el-button>
        <el-button @click="updateDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        addDialogVisible: false,
        updateDialogVisible: false,
        categories: [],
        // 新增相关
        itemCategory: {
          sort: 1,
        },
      };
    },
    mounted() {
      window.vue = this;
      this.loadCategories();
    },
    methods: {
      loadCategories() {
        this.$ajax.get('/item/category').then(res => {
          if (res.code !== 100) {
            return;
          }
          this.categories = res.data;
        });
      },
      addItemCategory() {
        this.$ajax.post('/item/category/add', this.itemCategory).then(res => {
          if (res.code !== 100) {
            return;
          }
          this.addDialogVisible = false;
          this.itemCategory ={sort: 1};
          this.loadCategories();
        });
      },
      updateItemCategory() {
        this.$ajax.post('/item/category/update', this.itemCategory).then(res => {
          if (res.code !== 100) {
            return;
          }
          this.updateDialogVisible = false;
          this.loadCategories();
        });
      },
      deleteItemCategory(category) {
        // 看看下面是不是有二级类目
        this.$ajax.get('/item/category/type', {params: {categoryId: category.categoryId}}).then(res => {
          if (res.data.length > 0) {
            this.$message.info("此类目下还有二级类目，请先删除");
          } else {
            this.$ajax.post('/item/category/delete', {categoryId: category.categoryId}).then(res => {
              this.loadCategories();
            })
          }
        });
      }
    }

  }
</script>


<style>
</style>
