<template>
  <div>
    <div ref="editor" style="text-align:left">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'editor',
    model: {
      prop: "content",
      event: "change"
    },
    props: {
      content: {
        type: String,
        default: ''
      }
    },
    methods: {
      setContent(content) {
        this.editor.txt.html(content)
      }
    },
    mounted() {
      const E = window.wangEditor;
      let editor = new E(this.$refs.editor);
      // editor.customConfig.uploadImgShowBase64 = true;   // 使用 base64 保存图片
      editor.customConfig.zIndex = 2000;
      editor.customConfig.onchange = html => {
        this.$emit("change", html);
      };
      editor.create();
      this.editor = editor;
    }
  }
</script>