<template>
  <el-container>
    <!-- 头部查询 -->
    <el-header height="90px">
      <el-button type="primary" @click="$router.push({path: '/home/banner-edit'})">新增</el-button>
    </el-header>

    <!-- 表格 -->
    <el-main>
      <el-table :data="queryResult.records">
        <el-table-column label="标题" prop="zhTitle" align="center"></el-table-column>
        <el-table-column label="图片" align="center" width="100">
          <template slot-scope="scope">
            <el-image :src="scope.row.imgUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="是否展示" align="center">
          <template slot-scope="scope">
            {{scope.row.onShow ? "展示" : "不展示"}}
          </template>
        </el-table-column>
        <el-table-column label="排序" align="center">
          <template slot-scope="scope">
            <span v-if="!scope.row.modifingSort">{{scope.row.sort}}</span>
            <el-input-number v-if="scope.row.modifingSort" v-model="scope.row.sort" :controls="false" size="mini" class="sort-edit-input"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <div v-if="!scope.row.modifingSort" class="flex flex-direction">
              <span class="link-type" @click="startModifySort(scope.$index)">修改排序</span>
              <span class="link-type" @click="$router.push({path: '/home/banner-edit',query: {bannerId: scope.row.bannerId}})">编辑</span>
              <span v-if="scope.row.onShow" class="link-type" @click="() => {selectBanner = scope.row;hideDialogVisible = true}">不展示</span>
              <span v-else class="link-type" @click="() => {selectBanner = scope.row;showDialogVisible = true}">展示</span>
              <span class="link-type" @click="() => {selectBanner = scope.row;deleteDialogVisible = true}">删除</span>
            </div>
            <div v-if="scope.row.modifingSort" class="flex flex-direction">
              <span class="link-type" @click="confirmModifySort(scope.$index)">保存排序</span>
              <span class="link-type" @click="cancelModifySort(scope.$index)">取消</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex margin-top-sm">
        <el-pagination
          layout="prev, pager, next"
          :total="queryResult.total"
          :page-size="queryParam.pageSize"
          :current-page.sync="queryResult.current"
          @current-change="pageChange">
        </el-pagination>
      </div>
    </el-main>

    <el-dialog title="提示" :visible.sync="showDialogVisible" width="30%">
      <span>确认展示？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="show">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="hideDialogVisible" width="30%">
      <span>确认隐藏？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="hide">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="deleteDialogVisible" width="30%">
      <span>确认删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="remove">确 定</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        selectBanner: {},
        showDialogVisible: false,
        hideDialogVisible: false,
        deleteDialogVisible: false,

        queryParam: {
          current: 1,
          size: 10,
          total: 0,
          searchCount: true,
        },
        queryResult: {
          records: [],
          total: 1,
          current: 1,
        }
      };
    },

    created() {
      window.vue = this;
      this.query();
    },
    methods: {
      query() {
        this.$ajax.get('/banner', {params: this.queryParam}).then(res => {
          this.queryResult = res.data;
        });
      },
      edit(bannerId) {
        this.$router.push({
          path: '/home/banner-edit',
          query: {
            bannerId: bannerId
          }
        });
      },
      show() {
        this.$ajax.post('/banner/show', {bannerId: this.selectBanner.bannerId}).then(res => {
          this.showDialogVisible = false;
          this.query();
        });
      },
      hide() {
        this.$ajax.post('/banner/hide', {bannerId: this.selectBanner.bannerId}).then(res => {
          this.hideDialogVisible = false;
          this.query();
        });
      },
      remove() {
        this.$ajax.post('/banner/delete', {bannerId: this.selectBanner.bannerId}).then(res => {
          this.deleteDialogVisible = false;
          this.query();
        });
      },

      startModifySort(atIndex) {
        const record = this.queryResult.records[atIndex];
        record.modifingSort = true;
        record.originSort = record.sort;
        this.$refreshData(this.queryResult.records);
      },
      cancelModifySort(atIndex) {
        const record = this.queryResult.records[atIndex];
        record.modifingSort = false;
        record.sort = record.originSort;
        record.originSort = undefined;
        this.$refreshData(this.queryResult.records);
      },
      confirmModifySort(atIndex) {
        const record = this.queryResult.records[atIndex];
        if (record.sort == null || record.sort < 0) {
          this.$message.error('排序必须为正整数！');
          return;
        }

        this.$ajax.post('/banner/update-sort', {
          bannerId: record.bannerId,
          sort: record.sort
        }).then(res => {
          if (res.code !== 100) {
            return;
          }

          this.query();
        });
      },

      pageChange(page) {
        this.queryParam.current = page;
        this.query();
      },
    }
  }
</script>


<style lang="scss">
  .sort-edit-input.el-input-number {
    width: 50px;
    input {
      padding: 0 2px !important;
    }
  }

  .stock-modify-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
  }

  .stock-edit-input.el-input-number {
    width: 100px;
  }

</style>
